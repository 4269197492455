/**
 * @generated SignedSource<<19c7335f6a5a4c9055d38344676b3aab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SlugPage_pageData$data = {
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"PageSeoText_data" | "SanityPage_page">;
  readonly " $fragmentType": "SlugPage_pageData";
};
export type SlugPage_pageData$key = {
  readonly " $data"?: SlugPage_pageData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlugPage_pageData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlugPage_pageData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SanityPage_page"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageSeoText_data"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "type": "Page",
  "abstractKey": null
};

(node as any).hash = "87b99f28dcda0813daaebbc6ddc86822";

export default node;
