import type { NextPageContext } from 'next';
import Router from 'next/router';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { isLanguageCode } from '@pafcloud/locale';

/**
 * This can be called both on server and client.
 * If the current path don't match the expectedPath, a redirect will be issued.
 */
export function redirectIfPathDontMatch(ctx: NextPageContext, expectedPath: string) {
  const currentUrl = getCurrentUrl(ctx);

  // next.js is inconsistent when it includes the language or not.
  // On the server it does not, on the client it does...
  const path = stripLanguageCode(currentUrl.pathname);
  if (path === expectedPath) {
    return;
  }

  redirectPathTo(ctx, expectedPath);
}

/**
 * This can be called both on server and client.
 * It will trigger a redirect on server and route change on client.
 */
export function redirectPathTo(ctx: NextPageContext, path: string) {
  const currentUrl = getCurrentUrl(ctx);

  const redirectUrl = new URL(path, currentUrl.origin);
  currentUrl.searchParams.forEach((value, key) => {
    redirectUrl.searchParams.append(key, value);
  });
  redirectUrl.hash = currentUrl.hash;

  if (ctx.res) {
    const languagePathPrefix = getLanguagePathPrefix(ctx);

    ctx.res.writeHead(301, {
      location: languagePathPrefix + redirectUrl.pathname + redirectUrl.search,
      // Browsers cache 301 indefinitely by default.
      // Prevent it from being too sticky if mistakes happens.
      'cache-control': 'max-age=300',
    });
    ctx.res.end();
  } else {
    // Prevent infinite redirect loops if faulty setup.
    if (currentUrl.searchParams.has('redirected')) {
      return;
    }

    redirectUrl.searchParams.set('redirected', '1');
    void Router.push(redirectUrl.pathname + redirectUrl.search + redirectUrl.hash);
  }
}

function getCurrentUrl(ctx: NextPageContext) {
  const origin = typeof window === 'undefined' ? `https://www.${$buildEnv.site}` : window.location.origin;
  return new URL(ctx.asPath ?? '/', origin);
}

function getLanguagePathPrefix(ctx: NextPageContext) {
  const locale = ctx.locale;
  if (locale == null || ctx.defaultLocale === locale) {
    return '';
  }

  return `/${locale}`;
}

function stripLanguageCode(path: string): string {
  const parts = path.split('/');
  if (parts[0] === '') {
    parts.shift();
  }

  if (!isLanguageCode(parts[0])) {
    return path;
  }

  parts.shift();
  return `/${parts.join('/')}`;
}
