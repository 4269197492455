import type { NextPageContext } from 'next';

const getSlugString = (slug: string | string[]) => {
  if (Array.isArray(slug)) {
    return slug.join('/');
  }
  return slug;
};

const stripLocale = (slug: string, locale: string | undefined) => {
  if (locale && slug.startsWith(`${locale}/`)) {
    return slug.slice(`^${locale}/`.length);
  }

  return slug;
};

export const getPageSlug = (ctx: NextPageContext) => {
  if (ctx.query.slug) {
    return stripLocale(getSlugString(ctx.query.slug), ctx.locale);
  }
};
