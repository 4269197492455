import { graphql, useFragment } from 'react-relay/hooks';
import { SanityPage } from '@pafcloud/sanity-blocks';
import { PageContainer } from '@pafcloud/base-components';
import { PageSeoText } from '@pafcloud/block-components';
import type { PageWithQueryData } from '../lib/PageWithData';
import { redirectPathTo } from '../lib/redirect';
import { getPageSlug } from '../lib/getPageSlug';
import { NotFoundPage } from '../lib/NotFoundPage';
import type { SlugPage_pageData$key } from './__generated__/SlugPage_pageData.graphql';
import type { SlugPageQuery } from './__generated__/SlugPageQuery.graphql';

const pageDataFragment = graphql`
  fragment SlugPage_pageData on Page {
    ...SanityPage_page
    ...PageSeoText_data
    slug
  }
`;

const SlugPage: PageWithQueryData<SlugPageQuery> = (props) => {
  const page = useFragment<SlugPage_pageData$key>(pageDataFragment, props.pageData.page);

  if (page == null) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PageContainer>
        <SanityPage page={page} key={page.slug} />
      </PageContainer>

      <PageSeoText data={page} />
    </>
  );
};

SlugPage.query = graphql`
  query SlugPageQuery($slug: String!) {
    page(slug: $slug) {
      slug
      ...SlugPage_pageData
    }
  }
`;

SlugPage.getInitialProps = (ctx) => ({
  queryArguments: {
    slug: getPageSlug(ctx) ?? '',
  },
  postQuery: (data) => {
    if (data.page == null) {
      if (ctx.res) {
        ctx.res.statusCode = 404;
      }
      return;
    }

    const pageSlug = getPageSlug(ctx);
    if (pageSlug !== data.page.slug) {
      // We are visiting a page from a non-canonical URL, redirect to the correct one.
      redirectPathTo(ctx, data.page.slug);
    }
  },
});

export default SlugPage;
